/* TOC

- Adjustments: Video Layout
- Navigation
- Search
- Masthead
- Masthead › small-only
- Masthead › medium-only
- Masthead › large-only
- Masthead › xlarge-up
- Breadcrumb
- Meta
- Jump to top
- Footer
- Subfooter
- CSS-Classes to add margin at top or bottom

*/



/* Adjustments: Video Layout
------------------------------------------------------------------- */

body.video,
body.video #masthead-no-image-header { background: #000; }
body.video #masthead-no-image-header { margin-bottom: 60px; }
body.video h1,
body.video h2,
body.video h3,
body.video h4,
body.video h5,
body.video h6,
body.video p,
body.video a,
body.video blockquote:before,
body.video blockquote:after,
body.video cite a, { color: $body-bg; }
body.video cite a:visited, { color: $body-bg; }
body.video cite { color: $body-bg; }



/* Navigation
------------------------------------------------------------------- */

#navigation {
    -webkit-box-shadow: 0 1px 1px 0 rgba(0,0,0,.2);
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.2);
}



/* Search
------------------------------------------------------------------- */

#search {
    padding: 20px 0 0 0;
    background: $ci-4;
}



/* Masthead
------------------------------------------------------------------- */

#masthead {
    background-color: $primary-color;
}
#masthead-no-image-header {
    background-color: $primary-color;
}
#masthead-with-text {
    text-align: center;
    font-size: rem-calc(36);
    font-family: $header-font-family;
    color: $s-orange;
    text-transform: none;
}
#masthead-no-image-header {
    height: 50px;
}
#masthead-no-image-header #logo img {
    margin-top: 20px;
}

/* Masthead › small-only
------------------------------------------------------------------- */

@media #{$small-only} {
    #logo img {
        display: none;
    }
    #masthead {
        height: 100px;
    }
    #masthead-with-pattern {
        padding: 15px 0;
    }
    #masthead-with-background-color {
        padding: 15px 0;
    }
    #masthead-with-text {
        height: 110px;
        padding: 30px 0;
        font-size: rem-calc(36);
    }
    #masthead-no-image-header {
        display: none;
    }
}


/* Masthead › medium-only
------------------------------------------------------------------- */

@media #{$medium-only} {
    #logo img {
        margin-top: 30px;
    }
    #masthead {
        height: 140px;
    }
    #masthead-with-pattern {
        padding: 20px 0;
    }
    #masthead-with-background-color {
        padding: 20px 0;
    }
    #masthead-with-text {
        padding: 30px 0;
        height: 150px;
    }
}


/* Masthead › large-only
------------------------------------------------------------------- */

@media #{$large-only} {
    #logo img {
        margin-top: 40px;
    }
    #masthead {
        height: 310px;
    }
    #masthead-with-pattern {
        padding: 30px 0;
    }
    #masthead-with-background-color {
        padding: 30px 0;
    }
    #masthead-with-text {
        height: 50px;
        padding: 45px 0;
    }
}


/* Masthead › xlarge-up
------------------------------------------------------------------- */

@media #{$xlarge-up} {
    #logo img {
        margin-top: 55px;
    }
    #masthead {
        height: 190px;
    }
    #masthead-with-pattern {
        padding: 45px 0;
    }
    #masthead-with-background-color {
        padding: 45px 0;
    }
    #masthead-with-text {
        padding: 48px 0;
        height: 100px;
    }
}


#title-image-small {
    height: 120px;
}
#title-image-large {
    height: 360px;
}
#title-image-index-small {
    height: 60px;
}
#title-image-index-large {
    height: 130px;
}



/* Breadcrumb
------------------------------------------------------------------- */

#breadcrumb {
    background: scale-color($grey-1, $lightness: 55%);
    border-top: 1px solid scale-color($grey-1, $lightness: 45%);
    border-bottom: 1px solid scale-color($grey-1, $lightness: 45%);
}
.breadcrumbs>.current {
  font-weight: bold;
}


/* Meta
------------------------------------------------------------------- */

#page-meta, #page-meta a {
    color: $grey-5;
}

#page-meta .button {
    background: $grey-5;
    border: 0;
}
#page-meta .button {
    color: $body-bg;
}
#page-meta .button:hover {
    background: $primary-color;
}
.meta-info p {
  font-size: rem-calc(13);
  color: scale-color($grey-1, $lightness: 40%);
}
  .meta-info a {
    text-decoration: underline;
    color: scale-color($grey-7, $lightness: 40%);
  }
  .meta-info a:hover {
    text-decoration: none;
    color: $secondary-color;
  }



/* Jump to top
------------------------------------------------------------------- */

#up-to-top {
    padding: 0px 0 0px 0;
}
#up-to-top a {
    font-size: 0px;
    padding: 0px;
    border-radius: 0px;
}
#up-to-top a:hover {
    background: $s-base2;
}



/* Footer
------------------------------------------------------------------- */

#footer-content p,
#footer-content li {
    font-size: rem-calc(13);
    font-weight: 200;
}

#footer {
    padding-top: 1px;
    padding-bottom: 1px;
    background: $footer-bg;
    color: $footer-color;
    }

    #footer a {
        color: $footer-link-color;
    }
    #footer h4,
    #footer h5 {
        letter-spacing: 1px;
        color: $footer-color;
        text-transform: uppercase;
    }

/** extras by CRD */
.centered-image-container {
  text-align: center;
  height: 110%
}

  .centered-image-container:before {
      content: '';
      height: 100%;
      display: inline-block;
      vertical-align: middle;
  }  

.centered-image {
  display: inline-block;
  vertical-align: bottom;
}


/* Subfooter
------------------------------------------------------------------- */

#subfooter {
    background: $subfooter-bg;
    color: $subfooter-color;
    padding-top: 10px;
    padding-bottom: 1px;
}
    #subfooter-left ul.inline-list {
        float: right;
    }
    #subfooter li a {
        color: $subfooter-link-color;
        text-transform: uppercase;
    }
    #subfooter li a:hover {
        color: $s-yellow;
    }
    #subfooter .social-icons li a {
        font-size: rem-calc(23);
        display: block;
        width: 36px;
        border-radius: 50%;
        color: $subfooter-bg;
        background: $subfooter-color;
        text-align: center;
    }
    #subfooter .social-icons li a:hover {
        background: $subfooter-bg;
        color: $body-bg;
    }



/* CSS-Classes to add margin at top or bottom
------------------------------------------------------------------- */

.t10 { margin-top: 10px !important; }
.t15 { margin-top: 15px !important; }
.t20 { margin-top: 20px !important; }
.t30 { margin-top: 30px !important; }
.t50 { margin-top: 50px !important; }
.t60 { margin-top: 60px !important; }
.t70 { margin-top: 70px !important; }
.t80 { margin-top: 80px !important; }
.t90 { margin-top: 90px !important; }

.b15 { margin-bottom: 15px !important; }
.b20 { margin-bottom: 20px !important; }
.b30 { margin-bottom: 30px !important; }
.b60 { margin-bottom: 60px !important; }

.l15 { margin-left: 15px !important; }
.r15 { margin-right: 15px !important; }

.pl20 { padding-left: 20px !important; }
.pr5  { padding-right: 5px !important; }
.pr10 { padding-right: 10px !important; }
.pr20 { padding-right: 20px !important; }
