/* TOC – Color Variables

- Solarized Colorpalette
- Basics
- Corporate Identity Colorpalette
- Foundation Color Variables
- Grey Scale
- Topbar-Navigation
- Footer
- Code

*/

/* Solarized Colorpalette
------------------------------------------------------------------- */
$s-base03: #002b36;
$s-base02: #073642;
$s-base01: #586e75;
$s-base00: #657b83;
$s-base0: #839496;
$s-base1: #93a1a1;
$s-base2: #eee8d5;
$s-base3: #fdf6e3;
$s-yellow: #b58900;
$s-orange: #cb4b16;
$s-red: #dc322f;
$s-magenta: #d33682;
$s-violet: #6c71c4;
$s-blue: #268bd2;
$s-cyan: #2aa198;
$s-green: #859900;

/* Basics
------------------------------------------------------------------- */

$text-color: $s-base00;
$light-text-color: $s-base1;
$dark-text-color: $s-base01;
$body-font-color: $text-color;
$body-bg: $s-base3;



/* Corporate Identity Colorpalette
   https://color.adobe.com/de/Flat-Design-Colors-v2-color-theme-4341903/
------------------------------------------------------------------- */

$ci-1: $s-cyan;		// dark turquoise
$ci-2: $s-blue;		// turquoise
$ci-3: $s-yellow;	// yellow
$ci-4: $s-orange;	// orange
$ci-5: $s-red;		// red
$ci-6: $s-green;       // green




/* Foundation Color Variables
------------------------------------------------------------------- */

$primary-color: $s-yellow;
$secondary-color: $s-base2;
$alert-color: $s-red;
$success-color: $s-green;
$warning-color: $s-orange;
$info-color: $s-orange;



/* Grey Scale
------------------------------------------------------------------- */

$grey-1: $s-base3;
$grey-2: $s-base3;
$grey-3: $s-base2;
$grey-4: $s-base2;
$grey-5: $s-base1;
$grey-6: $s-base1;
$grey-7: $s-base0;
$grey-8: $s-base0;
$grey-9: $s-base00;
$grey-10: $s-base00;
$grey-11: $s-base01;
$grey-12: $s-base01;
$grey-13: $s-base02;
$grey-14: $s-base02;
$grey-15: $s-base03;
$grey-16: $s-base03;


/* Topbar-Navigation
------------------------------------------------------------------- */

$topbar-bg-color: $s-base2;

$topbar-dropdown-toggle-color: $s-base2;

$topbar-link-color: $s-cyan;
$topbar-link-color-hover: $s-yellow;
$topbar-link-color-active: $s-cyan;
$topbar-link-color-active-hover: $s-yellow;

$topbar-dropdown-label-color: $s-base2;
$topbar-dropdown-link-bg-hover: darken( $s-base2, 5% );

$topbar-link-bg-active: $s-base2; // Active Navigation Link
$topbar-link-bg-hover: darken( $s-base2, 5% );
$topbar-link-bg-active-hover: $s-base2;


$topbar-dropdown-bg: $s-base2; // Background Mobile Navigation
$topbar-dropdown-link-color: $s-base00;
$topbar-dropdown-link-bg: $s-base2;

$topbar-menu-link-color-toggled: $s-orange;
$topbar-menu-icon-color-toggled: $s-base2;
$topbar-menu-link-color: $text-color;
$topbar-menu-icon-color: $text-color;
$topbar-menu-link-color-toggled: $s-base2;
$topbar-menu-icon-color-toggled: $s-base2;



/* Footer
------------------------------------------------------------------- */

$footer-bg: $s-base2;
$footer-color: $s-base00;
$footer-link-color: $s-base00;

$subfooter-bg: darken( $s-base2, 5% );
$subfooter-color: $s-base00;
$subfooter-link-color: $s-base00;

/* added by CRD */
$footer-contact-title-color: $s-yellow;
$footer-menu-title-color: $s-cyan;


/* Code
------------------------------------------------------------------- */

$code-background-color: scale-color($secondary-color, $lightness: 70%);

$highlight-background: $s-base2;
$highlight-comment: $s-base0;
$highlight-error: $s-red;
$highlight-comment-special: $s-violet;
$highlight-deleted: $text-color;
$highlight-error-2: $s-red;
$highlight-literal-string: $s-cyan;
$highlight-literal-number: $s-magenta;
$highlight-name-attribut: #008080;
$highlight-error-background: $s-base2;
$highlight-generic-deleted: #ffdddd;
$highlight-generic-deleted-specific: #ffaaaa;
$highlight-generic-inserted: #ddffdd;
$highlight-generic-inserted-specific: #aaffaa;
$highlight-generic-output: #888888;
$highlight-generic-prompt: #555555;
$highlight-subheading: #aaaaaa;
$highlight-keyword-type: $s-green;
$highlight-name-builtin: $s-yellow;
$highlight-name-class: $s-green;
$highlight-name-entity: $s-violet;
$highlight-name-exception: $s-red;
$highlight-name-function: $s-blue;
$highlight-name-namespace: $s-base0;
$highlight-name-tag: $s-blue;
$highlight-text-whitespace: $s-base2;
$highlight-literal-string-regex: $s-violet;
$highlight-literal-string-symbol: $s-violet;
